import { Waves } from "assets";
import styles from "./styles.module.css";

const Stats = () => {
  return (
    <section className={`homeContainer ${styles.container}`}>
      <p className={styles.ttl}>Figures you should know</p>
      <p className={styles.txt}>
        We are constantly building lasting relationships across border and
        enabling success everywhere we are.
      </p>
      <div className={styles.stats}>
        <div className={styles.stat}>
          <p className={styles.stat__value}>5 mins</p>
          <p className={styles.stat__text}>Response time </p>
        </div>
        <div className={styles.stat}>
          <p className={styles.stat__value}>24hrs</p>
          <p className={styles.stat__text}>Turn around time</p>
        </div>
        <div className={styles.stat}>
          <p className={styles.stat__value}>100%</p>
          <p className={styles.stat__text}>Transparency </p>
        </div>
      </div>
      <Waves className={styles.pattern} />
    </section>
  );
};

export { Stats };
