import { ImgWithFallback } from "components";
import styles from "./styles.module.css";
import { AtlasLogoHorizontal, authImgFallback, authImgWebp } from "assets";
import { Routes } from "router";
import { Link } from "react-router-dom";

const AuthLayout = ({ children }) => {
  return (
    <>
      <main className={styles.container}>
        <section className={styles.body}>
          <Link className={styles.logoLink} to={Routes.home}>
            <AtlasLogoHorizontal className={styles.logo} />
          </Link>
          <div className={styles.content}>{children}</div>
          <a href="mailto:support@vobb.io" className={styles.mail}>
            support@vobb.io
          </a>
        </section>
        <ImgWithFallback
          className={styles.img}
          src={authImgWebp}
          fallbackSrc={authImgFallback}
          alt="Illustration of a man celebrating with raised arms, representing the all-in-one application portal"
        />
      </main>
    </>
  );
};

export { AuthLayout };
