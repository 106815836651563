import { Dots } from "assets";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { Routes } from "router";

const GetStarted = () => {
  return (
    <section className={styles.containerBg}>
      <div className={`homeContainer ${styles.container}`}>
        <Dots className={styles.dot1} />
        <p className={styles.tag}>Explore Vobb Atlas</p>
        <h6 className={styles.ttl}>Ready to get started?</h6>
        <p className={styles.txt}>
          Be a part of the top travel businesses engaging and connecting with
          their customers using Vobb.
        </p>
        <Link className={styles.link} to={Routes.signup}>
          Signup
        </Link>
        <Dots className={styles.dot2} />
      </div>
    </section>
  );
};

export { GetStarted };
