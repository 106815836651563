import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserState } from "./userTypes";
import * as userReducers from "./userReducers";

const initialState: UserState = {
  type: null,
  token: "",
  details: { student: null, agent: null },
  isAuth: false,
  loading: false,
};

const fetchUser = createAsyncThunk("user/checkToken", userReducers.checkToken);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: userReducers.setUser,
    deleteUser: userReducers.deleteUser,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, userReducers.startLoadingUser)
      .addCase(fetchUser.fulfilled, userReducers.setUser);
  },
});

export const userActions = { ...userSlice.actions, fetchUser };

export default userSlice.reducer;
