import { Footer } from "./footer";
import { Navbar, NavbarProps } from "./navbar";
import styles from "./styles.module.css";

export interface HomeLayoutProps extends NavbarProps {
  children: any;
}

const HomeLayout = ({ children, active }) => {
  return (
    <>
      <Navbar active={active} />
      <main className={styles.main}>{children}</main>
      <Footer />
    </>
  );
};

export { HomeLayout };
