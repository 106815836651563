import axios from "axios";

// Creates a base instance for all axios based request
// So no need the call the full url when using this
// Just call axios.get("/jobs")
// We could set other properties here like headers, etc

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("vobbToken");

    config.headers = {
      "x-auth-token": token ?? null,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Log a user out with an expired token
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 401 &&
      (window.location.pathname.startsWith("/agent") ||
        window.location.pathname.startsWith("/prospects"))
    ) {
      window.location.assign("/login");
      localStorage.clear();
      // Redirect user to login page if not authorized
      // TODO: Add redirect logic
    } else return Promise.reject(error);
  }
);

export default instance;
