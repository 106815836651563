import { AtlasLogoHorizontal, AtlasLogomark } from "assets";
import styles from "./styles.module.css";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { Routes } from "router";

type tabs = "students" | "agents" | "institutions" | "pricing" | "blog";

export interface NavbarProps {
  active: tabs;
}

const Navbar: React.FC<NavbarProps> = ({ active }) => {
  const history = useHistory();
  const [showNav, setShowNav] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 800 ? true : false);

  const screenSizeUpdate = () => {
    if (window.innerWidth <= 800) {
      setMobile(true);
    } else if (window.innerWidth > 800) {
      setMobile(false);
      setShowNav(false);
    }
  };
  window.addEventListener("resize", screenSizeUpdate);

  const navlinks = [
    {
      title: "For Students",
      path: Routes.home,
      id: "students",
    },
    {
      title: "For Agents",
      path: Routes.agents,
      id: "agents",
    },
    {
      title: "Institutions",
      path: Routes.institutions,
      id: "institutions",
    },
    {
      title: "Pricing",
      path: Routes.pricing,
      id: "pricing",
    },
    // {
    //   title: "Blog",
    //   path: Routes.blog,
    //   id: "blog",
    // },
  ];
  return (
    <>
      <section className={styles.containerBg}>
        <header className={`homeContainer ${styles.container}`}>
          <div className={styles.logoWrap}>
            <Link to={Routes.home}>
              {!mobile ? (
                <AtlasLogomark className={styles.logo} />
              ) : (
                <AtlasLogoHorizontal className={styles.logo} />
              )}
            </Link>
            <button
              onClick={() => setShowNav(!showNav)}
              className={`${styles.hamburger} ${
                showNav ? styles.closeMenuBtn : ""
              }`}
            >
              <div></div>
              <div></div>
              <div></div>
            </button>
          </div>
          {((showNav && mobile) || !mobile) && (
            <>
              <nav className={styles.nav}>
                {navlinks.map((tab, index) => (
                  <Link
                    className={active === tab.id ? styles.active : ""}
                    key={index}
                    to={tab.path}
                  >
                    {tab.title}
                  </Link>
                ))}
              </nav>
              <button
                onClick={() => history.push(Routes.login)}
                className={styles.btn}
              >
                Agent's Sign in
              </button>
            </>
          )}
        </header>
      </section>
    </>
  );
};

export { Navbar };
