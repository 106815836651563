import { createSlice } from "@reduxjs/toolkit";
import { MobileState } from "./mobileTypes";
import { setMobile, resetMobile } from "./mobileReducers";

const initialState: MobileState = {
  isMobile: false,
};

const mobileSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: { setMobile, resetMobile },
});

export const mobileActions = { ...mobileSlice.actions };

export default mobileSlice.reducer;
