import styles from "./styles.module.css";

const ColorBlurAnimation = () => {
  return (
    <>
      <div className={styles.blur}></div>
    </>
  );
};

export { ColorBlurAnimation };
