import { PayloadAction } from "@reduxjs/toolkit";
import { MobileState } from "./mobileTypes";

export function setMobile(state: MobileState, _: PayloadAction) {
  if (!state.isMobile) state.isMobile = true;
}

export function resetMobile(state: MobileState, _: PayloadAction) {
  if (state.isMobile) state.isMobile = false;
}
