import styles from "./styles.module.css";

interface PriceCardProps {
  name: string;
  text: string;
  benefits: string[];
  cost: string;
  restriction: string;
  className?: string;
}

const PriceCard: React.FC<PriceCardProps> = ({
  name,
  text,
  benefits,
  cost,
  restriction,
  className,
}) => {
  return (
    <div className={`${className} ${styles.price}`}>
      <div className={styles.info}>
        <p className={styles.info__txt1}>{name}</p>
        <p className={styles.info__txt2}>{text}</p>
      </div>
      <div className={styles.benefits}>
        <p>Includes</p>
        <ul>
          {benefits.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      </div>
      <div className={styles.value}>
        <p className={styles.value__ttl}>{cost}</p>
        <p className={styles.value__txt}>{restriction}</p>
      </div>
    </div>
  );
};

export { PriceCard };
