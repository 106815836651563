import {
  AtlasLogoHorizontal,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
} from "assets";
import styles from "./styles.module.css";

const Footer = () => {
  return (
    <>
      <footer className={`homeContainer ${styles.footer}`}>
        <AtlasLogoHorizontal className={styles.logo} />
        <div className={styles.sec1}>
          <div className={styles.socials}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://instagram.com/vobb_atlas?igshid=NTc4MTIwNjQ2YQ=="
            >
              <InstagramLogo />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://twitter.com/vobb_atlas?t=EXqci5lqyqA4MkDj8a5QiQ&s=09"
            >
              <TwitterLogo />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/vobb-io/"
            >
              <LinkedinLogo />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/VobbSA/"
            >
              <FacebookLogo />
            </a>
          </div>
          <p className={styles.copyright}>© 2021 Vobb. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
};

export { Footer };
