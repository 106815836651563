import * as React from "react";
import styles from "./styles.module.css";

const TutorialWidget = () => {
  return (
    <a
      title="Tutorials"
      className={styles.widget}
      href="https://vobb.notion.site/Tutorials-a0d86e124641427d9e19d2a58b278303"
      target={"_blank"}
      rel={"noreferrer"}
    >
      ?
    </a>
  );
};

export default TutorialWidget;
